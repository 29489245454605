<template>
  <div>
    <div class="pg" >
      
      <video  class="pg-video" autoplay='autoplay' loop="loop" muted="muted" preload="auto">
        <source :src="cn_enobj.site.index.home_pic" type="video/webm" />
        <source :src="cn_enobj.site.index.home_pic" type="video/mp4" />
      </video>
      <div class="pg-img">
        <img :src="cn_enobj.site.index.wap_pic" alt="">
      </div>
      <Top :topIndex="0"   @changeL="changeL"></Top> 
      <div class="zhushi">{{cn_enobj.site.index.footer_text}}</div>
    </div>
  </div>
</template>

<script>
import Top from '@/components/top.vue'
import scrollReveal from 'scrollreveal'

export default {  
  components:{Top},
  data () {
      return {
        scrollReveal: scrollReveal(),
        locale:'',
        cn_enobj:{site:{index:{}}},
        enobj:'',
        cnobj:''
      }
  },
  
  
  //项目中执行，自定义类名。
  mounted() {
      this.scrollReveal.reveal('.reveal-bottom', {origin: 'bottom',...this.global});
      this.scrollReveal.reveal('.reveal-r', {...this.global,distance: '0px', scale: 0.9});
     
  },
  created(){
    if(!sessionStorage.getItem("enobj")){
      this.getdata()
    }
    this.changeL(this.$i18n.locale)
  },


  methods: {
      getdata(){
        this.$axios({
          url:'/api/common/global_en.js',
          method:'get'
        }).then(res=>{   
          this.$axios({
            url:'/api/common/global_zh.js',
            method:'get'
          }).then(res2=>{

            this.cn_enobj = this.$i18n.locale == 'zh' ?res2.data:res.data
            sessionStorage.setItem("enobj", JSON.stringify(res.data));
            sessionStorage.setItem("cnobj",  JSON.stringify(res2.data));
          })
          
        })
      },
      changeL(str){
          this.locale = str
          this.cn_enobj = this.$i18n.locale == 'zh' ?JSON.parse(sessionStorage.getItem("cnobj")):JSON.parse(sessionStorage.getItem("enobj"))
      },
   
        
  }

}
</script>

<style lang="less" scoped>
  @import "../../src/assets/css/pc/index_pc.less";
 .pg-video{
   position: absolute;
   top:0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover;
 }
 .pg-img{
   display: none;
 }
  @media screen and (max-width: 600px) {
    .pg-video{
      display: none;
    }
    .pg-img{
      display: block;
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width:70vw
      }
    }
  }

  @media screen and (max-height: 500px) {
    .pg-video{
      display: none;
    }
    .pg-img{
      display: block;
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width:20vw
      }
    }
  }

</style>
