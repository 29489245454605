<template>
    <div class="pgtop">
        <div class="top">
            <img :src="cn_enobj.site.index.home_logo" @click="gotoSubpage('home',0)"  class="left" alt="">
            <div class="right">
                <div v-if="openMenu" class="showM" :class="openMenu?'showclass':'detail_wrap'">
                    <div class="menu" v-for="(i,index) in cn_enobj.menu" :key="index" 
                    :class="{'active':topIndex===index}" 
                    @click="gotoSubpage(i,index)">{{$t(i.title)}}</div>
                </div>
               <div class="en-cn" @click="changeLanguage()">{{$t(cn_enobj.zh_en)}}</div>
               <div>
                    <img :src="openMenu?'img/gbcaidan.png':'img/caidan.png'"   :class="openMenu?'gbcaidanImg':'caidanImg'" 
                    @click="openMenuEV()"  @mouseenter="YRopenMenuEv()"  alt="">
               </div>
            </div>
        </div>
        <div class="pop" v-if="openMenu">
            <div :class="openMenu?'showclass':'detail_wrap'">
                    <div class="menu" v-for="(i,index) in cn_enobj.menu" :key="index" 
                    :class="{'active':topIndex===index}" 
                    @click="gotoSubpage(i,index)">{{$t(i.title)}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
     props: {
        //  1、只有首页显示 ‘菜单’ 文字  
        //  2、只有非首页 才显示 ‘首页’导航 
        showMenu: {
            type: Boolean,
            default: false // 1、只有首页显示 ‘菜单’ 文字  2、只有非首页 才显示 ‘首页’导航
        },
        topIndex:{
            type:Number,
            default:0
        },
       
    },
    data(){
        return{
            type:1, // 1:初始状态 |2:非初始状态
            openMenu:false,// 是否显示蒙版
            cn_enobj:{},
        }
    },
    created(){
        this.cn_enobj = this.$i18n.locale == 'zh' ?JSON.parse(sessionStorage.getItem("cnobj")):JSON.parse(sessionStorage.getItem("enobj"))
    },

    methods:{

        /**
         * 打开菜单函数
         * @param  参数
         * @returns 返回值
         */
        openMenuEV(){
            this.openMenu = !this.openMenu
        },
        // 鼠标滑入事件
        YRopenMenuEv(){
            if(localStorage.getItem("equipment")==='move')return
            this.openMenu = true
        },
        
        /**
         * 修改语言函数
         */
        changeLanguage(){
            if(JSON.parse(sessionStorage.getItem("cnobj")).site.index.lang_support != 'zh-en') return
            let locale = this.$i18n.locale
            locale === 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh'
            this.cn_enobj = this.$i18n.locale == 'zh'?JSON.parse(sessionStorage.getItem("cnobj")):JSON.parse(sessionStorage.getItem("enobj")) 
            this.$emit('changeL',this.$i18n.locale)
        },
        /**
         * 前往其他菜单页面函数
         * @param  type  参数
         * @returns 返回值
         */
        gotoSubpage(i,type){
            if(type === 0){ // 首页
                if(this.topIndex ===0){
                    this.$router.go(0)
                }else{
                    this.$router.push('/')
                }
                
            }else if(this.topIndex === type){
                this.$router.go(0)
            }
            else{
                 this.$router.push(i.path)
            }
            // if(type === 1){ // 关于我们
            //     this.$router.push('/aboutUs')
            // }
            // if(type === 2){ // 投资案例
            //     this.$router.push('/inCase')
            // }
            // if(type === 3){ // 新闻中心
            //     this.$router.push('/newCenter')
            // }
            // if(type === 4){ // 联系我们
            //     this.$router.push('/contactUs')
            // }
        }

    }
}
</script>

<style lang="less" scoped>
.pgtop{
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 66;
}
.detail_wrap{ // 这是初始状态
    animation: anim2 2s;
    transition:2s
}
.showclass{
    animation: anim 2s
}
@keyframes anim { 
	0% {opacity: 0;}
	100%{opacity:1;}
}
@keyframes anim2 { 
	0% {opacity: 1;}
	100%{opacity:0;}
}
.top{
    width: 100%;
    // height: 9.12vh;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 58;
    padding: 2vh 0;
    
}
.left{
    width: 10vw;
    margin-left: 5vw;
}
.showM{
    display:flex;
}
.right{
    height: 100%;
    margin-right:5vw ;
    display: flex;
    align-items: center;
    .menu{
        white-space:nowrap;
        font-size: 2vmin;
        margin-right: 2vw;
        position: relative;
        display: flex;
        align-items: center;
    }
    .active{
        font-size: 2vmin;
        margin-right: 2vw;
        position: relative;
        color: #1CAE1B;
        padding-bottom: 0.5vh;
        border-bottom: 2px solid #1CAE1B;

    }
    .menu::after{ 
        content: '';
        height: 2px;
        background-color: #1CAE1B;
        position:absolute;
        bottom: -2px;
        left: 50%;
        transform: translate(-50%);
    }
    .menu:hover{
        color: #1CAE1B;
    }
    .menu:hover:after{
        animation-name: ani1;
        animation-duration:.5s;
        animation-fill-mode:forwards;
        animation-direction:alternate;
    }
    @keyframes ani1{
        from{
            width: 0;
        }
        to{
            width: 100%;
        }
    }

}
.en-cn{
    font-size:2vmin;font-weight:400;white-space:nowrap;margin-bottom:0.2vh;margin-right: 3vmin;
}
.caidanImg{
    width:3vmin;height:2.5vmin;
}
.gbcaidanImg{
    width:3vmin;height:3vmin;
}
.pop{
    display: none;
}
@media screen and (max-width: 600px) {

    .showM{
        display:none;
    }
   
    .caidanImg{
        width:6.5vmin;height:5vmin;
    }
    .gbcaidanImg{
        width:6.5vmin;height:6.5vmin;
    }
    .menu{
        white-space:nowrap;
        font-size: 4.4vmin;
        margin-right: 2vw;
        margin-left: 5vw;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        font-weight: bold;
        color: #fff;
    }
    .pop{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        z-index: 19;
        background: rgba(0,0,0,0.6);
    }
    .left{
        width: 20vw;
        margin-left: 5vw;
    }
    .en-cn{
        font-size:3.8vmin;font-weight:400;white-space:nowrap;margin-bottom:0.2vh
    }
}


 @media screen and (max-height: 500px) {
    .right2{
        display: none;
    }
     .caidanImg{
        width:6.5vmin;height:5vmin;
    }
    .gbcaidanImg{
        width:6.5vmin;height:6.5vmin;
    }
    .menu{
        white-space:nowrap;
        font-size: 4.4vmin;
        margin-right: 2vw;
        position: relative;
        display: flex;
        align-items: center;
        font-weight: bold;
        
    }
    .en-cn{
        font-size:3.8vmin;font-weight:400;white-space:nowrap;margin-bottom:0.2vh
    }
  }

</style>