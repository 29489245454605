import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n'
import BaiduMap from 'vue-baidu-map'

import animated from 'animate.css'
Vue.use(animated)
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper)
import Axios from 'axios'
let url = "http://www.starlightvc.com"
if(process.env.NODE_ENV==='development'){
  //开发环境下的执行操作
  url = "http://haochen.test:8000"
}else if(process.env.NODE_ENV==='test'){
  //测试环境下的执行操作
  url = "http://haochen.test:8000"
}else{
  //生产环境下的执行操作
  url = "http://www.starlightvc.com"
}
// 创建实例
let instance = Axios.create({
  // 每次请求的协议、IP地址。  设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
  // baseURL: "http://101.34.160.100",
  // baseURL:'http://haochen.test:8000',
  baseURL: url,
  // 请求超时时间
  timeout: 90000,

})
Vue.prototype.$axios = instance;//这个axios用来发请求

// 动画样式
Vue.prototype.global =  {
    duration: 800,// 动画的时长
    delay: 300, // 延迟时间
    // reset: true,// 回滚的时候是否再次触发动画
    mobile: false,// 在移动端是否使用动画
    distance: '50px',// 滚动的距离，单位可以用%，rem等
    opacity: 0.001,// 其他可用的动画效果
    easing: 'linear',// 其他可用的动画效果
    // scale: 0.9,// 其他可用的动画效果
}
// 全局封装回顶函数
Vue.prototype.$scrollFun = function (){//changeData是函数名
  var element = document.getElementById("box");
  element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

Vue.use(BaiduMap, {
  ak: 'YOUR_APP_KEY'  //这个地方是官方提供的ak密钥
})

Vue.use(VueI18n)
Vue.use(ElementUI);
Vue.config.productionTip = false

instance({
  url:'/api/common/global_zh.js',
  method:'get'
}).then(res=>{
    sessionStorage.setItem("cnobj", JSON.stringify(res.data));
    if(res.data.site.index.lang_support == 'zh'){
      localStorage.setItem("lang_support", 'zh');
    }else if(res.data.site.index.lang_support == 'en'){
      localStorage.setItem("lang_support",'en');
    }
}).catch(err => {console.log(err);});
instance({
  url:'/api/common/global_en.js',
  method:'get'
}).then(res=>{   
  sessionStorage.setItem("enobj",  JSON.stringify(res.data));
}).catch(err => {console.log(err);});

setTimeout(() => {
  console.log('lang_support',localStorage.getItem("lang_support"))

  const i18n = new VueI18n({
    locale: localStorage.getItem("lang_support"),  // 语言标识
    messages: {
        'zh': JSON.parse(sessionStorage.getItem("cnobj")),
        'en': JSON.parse(sessionStorage.getItem("enobj"))
    }
  })
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}, 500);


