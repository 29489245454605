<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
export default {  
  data () {
      return {
        type:1, // 1:初始状态 |2:非初始状态
        
      } 
  },
  methods: {
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  },
  mounted() {
    if(this.isMobile()) {
      localStorage.setItem("equipment", 'move');
    }else {
      localStorage.setItem("equipment", 'pc');
    }
  }
}
</script>
<style lang="less">
*{
    margin: 0;
    padding: 0;
}

.labelsTop{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10vh 0;
}
.labelsLeft{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10vh 0;
  .lvse{
    height: auto;
    width: 4px;
    margin-right: 1vw;
    background: #01AE0D;
  }
}
.labels{
    height: 46px;
    line-height: 46px;
    font-size: 3vmin;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    white-space:nowrap;
    padding: 0 1vw 0vh 1vw;
    border-bottom: 0.6vh solid #01AE0D;
    text-align: center;
}
.labels-en{
  font-size:1.6vmin;
  color: #BCBCBC;
  font-family: Times New Roman;
  font-weight: 400;
  height: 2vh;
}
// 加载更多
.loadMore{
    width: 100%;
    // height: 7.4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5vh 0;
    
}
.loadMore-btn{
    // width: 10vw;
    // height: 5vh;
    padding: 0.5vh 1.5vw;
    background: #FFFFFF;
    border: 2px solid #38AD3E;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #38AD3E;
    white-space:nowrap;
    font-size: 2vmin;
}
.zhanweiImg{
  width: 10vw;
  visibility:hidden
}
// JSON US || VIEW MORE
.imgbtn{
    margin-bottom: 4vh;
    padding: 0.4vh 2vw 0.4vh 1.5vw;
    font-size: 2vmin;
    background-image: url('assets/img/tx.png');
    background-size: cover;
    background-repeat:no-repeat;
    background-size:100% 100%;
    -moz-background-size:100% 100%;
    color: #FFFFFF;
    white-space:nowrap;
}

.zhushi{
    position: fixed;
    bottom: 2vh;
    right:0;
    font-size: 1.2vmin;
    font-weight: 300;
    width: 100%;
    color: #BCBCBC;
    text-align: center;
  }
  .pg-img{
      height: auto;
      position: absolute;
      top:0;
      left: 0;
      min-width:100%;
      height: 100vh;
      // background-position:center;
  }
  @media screen and (max-width: 1300px) {
      .pg-img{
        height: auto;
        position: absolute;
        top:0;
        left: 0;
        width:1890px;
        height: 100vh;
      }
  }
  @media screen and (max-width: 700px) {
    .labelsTop{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 10vh 0;
    }
    
    .labelsLeft{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5vh 0;
    }
    .labels{
        height: auto;
        font-size: 4.4vmin;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        white-space:nowrap;
        padding: 0 1vw 0vh 1vw;
        border-bottom: 0.6vh solid #01AE0D;
        text-align: center;
    }
    .labels-en{
      font-size:2.6vmin;
      color: #BCBCBC;
      font-family: Times New Roman;
      font-weight: 400;
      height: 2vh;
    }
    // 加载更多
    .loadMore{
        width: 100%;
        height: 7.4vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0;
        
    }
    .loadMore-btn{
        // width: 10vw;
        // height: 5vh;
        padding: 0.5vh 1.5vw;
        background: #FFFFFF;
        border: 1px solid #38AD3E;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #38AD3E;
        white-space:nowrap;
        font-size: 2vmin;
    }
    // JSON US || VIEW MORE
    .imgbtn{
        margin-bottom: 4vh;
        padding: 0.4vh 2vw 0.4vh 1.5vw;
        font-size: 2vmin;
        background-image: url('assets/img/tx.png');
        background-size: cover;
        background-repeat:no-repeat;
        background-size:100% 100%;
        -moz-background-size:100% 100%;
        color: #FFFFFF;
        white-space:nowrap;
    }

    .zhushi{
        position: fixed;
        bottom: 2vh;
        right:0;
        font-size: 2.4vmin;
        font-weight: 300;
        width: 100%;
        color: #BCBCBC;
        text-align: center;
      }
      .pg-img{
        height: auto;
        position: absolute;
        top:0;
        left: 0;
        width:1290px;
        height: 100vh;
        // background-position:center;
      }
      .zhanweiImg{
        width: 20vw;
        visibility:hidden
      }
  }




  @media (max-height: 500px) {
    .imgbtn{
        padding: 8px 2vw 8px 1.5vw;
    }
    .labels{
      height: auto;
      font-size: 4.4vmin;
    }
    .labels-en{
      font-size:2.6vmin;
    }
  }
</style>
