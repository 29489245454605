import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/investmentCase',
    name: 'InCase',
    component: () => import('../views/investmentCase.vue')
  },
  {
    path: '/newCenter',
    name: 'NewCenter',
    component: () => import('../views/newCenter.vue')
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('../views/contactUs.vue')
  },
  {
    path: '/inCaseDetaile',
    name: 'InCaseDetaile',
    component: () => import('../views/detaile/investmentCaseDetaile.vue')
  },
  {
    path: '/honor',
    name: 'Honor',
    component: () => import('../views/detaile/honorMore.vue')
  }
 
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
